import React, { FC } from "react";
import { Grid, Typography } from "utils/material-ui-core";
import Inventory from "./Inventory";
import Delivery from "./Delivery";
import Restrictions from "./Restrictions";
import { RHUK_HOST } from "utils/constants";
import { usePageContent } from "customProviders/LocationProvider";
import classNames from "classnames";
import useSite from "hooks/useSite";
import useStyles from "./useStyles";

export interface SkuProps {
  hideRestrictions: boolean;
  skuInventoryMessage?: string;
  availabilityStatus?: string;
  sku?: ProductSku | null;
  onPostalCode: () => void;
  showSPOmsg: boolean;
  inStock?: boolean;
  giftCardValueExceed?: boolean;
  isMultiSku?: boolean;
  multiSkuSPOReturnMessage?: string;
  multiSkuCountryRestrictions?: string[];
  isSwatchPanelOpen?: boolean;
  spoOverrideMessage?: string;
  isNewPDPLayout?: boolean;
  surchargeMessage?: any;
  loading?: boolean;
  parentBaseId?: string;
  isDisabled?: boolean;
}

export const Sku: FC<SkuProps> = ({
  sku,
  isDisabled,
  skuInventoryMessage,
  onPostalCode,
  hideRestrictions,
  showSPOmsg,
  inStock,
  giftCardValueExceed,
  availabilityStatus,
  isMultiSku,
  multiSkuSPOReturnMessage,
  multiSkuCountryRestrictions,
  isSwatchPanelOpen,
  spoOverrideMessage,
  isNewPDPLayout,
  surchargeMessage,
  loading,
  parentBaseId
}) => {
  const baseId = `${parentBaseId}-sku`;
  const { pageContent } = usePageContent();
  const classes = useStyles();
  const siteId = useSite();

  const inventoryEta =
    siteId === RHUK_HOST
      ? sku?.fulfillmentEta
      : sku?.fulfillmentEta || sku?.inventory;

  return (
    <Grid
      container
      spacing={1}
      data-testid="image-container-sku"
      id="image-container-sku"
      className={classNames({ [classes.textCenter]: isSwatchPanelOpen })}
    >
      {giftCardValueExceed && (
        <Typography
          component="span"
          style={{ paddingLeft: "0.5rem", color: "#CA6667" }}
        >
          {pageContent?.GIFTCARD_LIMIT}
        </Typography>
      )}
      <Inventory
        inStock={inStock}
        skuInventoryMessage={skuInventoryMessage}
        inventory={isMultiSku ? sku?.inventory : inventoryEta!}
        delivery={sku?.delivery}
        onPostalCode={onPostalCode}
        showSPOmsg={showSPOmsg || false}
        spo={sku?.restrictions?.spo!}
        isSwatchPanelOpen={isSwatchPanelOpen}
        isNewPDPLayout={isNewPDPLayout}
        loading={loading}
        isDisabled={isDisabled}
      />
      <Delivery
        delivery={sku?.delivery}
        onPostalCode={onPostalCode}
        isSwatchPanelOpen={isSwatchPanelOpen}
        inventory={isMultiSku ? sku?.inventory : inventoryEta!}
      />
      {surchargeMessage}
      {isMultiSku ? (
        <Restrictions
          restrictions={{
            ...sku?.restrictions,
            returnPolicyMessage: multiSkuSPOReturnMessage,
            countryRestrictions: multiSkuCountryRestrictions
          }}
          hideReturnPolicyMessage={hideRestrictions}
          availabilityStatus={availabilityStatus}
          hideCountryRestrictions={false}
          isSwatchPanelOpen={isSwatchPanelOpen}
          spoOverrideMessage={spoOverrideMessage}
        />
      ) : (
        <Restrictions
          restrictions={sku?.restrictions!}
          availabilityStatus={availabilityStatus}
          hideReturnPolicyMessage={hideRestrictions}
          hideCountryRestrictions={false}
          isSwatchPanelOpen={isSwatchPanelOpen}
          spoOverrideMessage={spoOverrideMessage}
        />
      )}
    </Grid>
  );
};

Sku.defaultProps = {};

export default Sku;
