import {
  ClickAwayListener,
  Grid,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  withStyles
} from "@material-ui/core";
import RHImage from "@RHCommerceDev/component-rh-image";
import React, { FC, useState, useRef, useEffect } from "react";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS,
  IMAGE_ASPECT_RATIO
} from "utils/constants";
import memoize from "utils/memoize";
import useTypographyStyles from "hooks/useTypographyStyles";
import classnames from "classnames";
import SwatchToolTip from "@RHCommerceDev/component-swatch-tooltip";
import RHInfoIconV2 from "icons/RHInfoIconV2";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton
} from "utils/material-ui-core";
import RHCloseIcon from "icons/RHCloseIcon";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    groupMaterial: {
      color: "var(--Reimagine-Black, #000)",
      fontFamily: "RHSans-Light",
      fontSize: "13px",
      letterSpacing: "0.52px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "110%" /* 14. 3px */,
      textTransform: "uppercase"
    },

    selectedSwatchTitle: (props: any) => ({
      color: "var(--ReimagineWarm-RH-Black, #000)",
      fontFamily: "RHSans-Light",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "100%" /* 13px */,
      letterSpacing: "0.26px",
      overflow: "hidden",
      maxWidth: "200px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "178px"
      },
      textAlign: "left",
      whiteSpace: "pre-wrap", // or word-wrap: break-word
      textOverflow: "clip"
    }),

    GridStyle: {
      alignSelf: "center"
    },
    fabricTitle: {
      alignSelf: "center",
      [theme.breakpoints.up("xs")]: {
        width: "100%",
        marginRight: "8px"
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "97px",
        minWidth: "97px"
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "97px",
        minWidth: "97px",
        marginRight: "8px"
      }
    },
    tooltipSubTitle: {
      "& strong": {
        fontSize: theme.typography.pxToRem(13),
        fontFamily: "RHSans-Light",
        fontWeight: 300,
        color: theme.palette.common.black,
        lineHeight: "13px",
        letterSpacing: "0.26px"
      },
      "& a": {
        color: "#000000",
        fontWeight: 400,
        fontFamily: "RHSans-Roman, Helvetica, Arial",
        lineHeight: "12.1px",
        fontSize: "11px",
        letterSpacing: " 0.44px"
      }
    },
    optionCopyContainer: {
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        maxWidth: "250px"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        maxWidth: "250px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        maxWidth: "336px"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        maxWidth: "394px"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        maxWidth: "500px"
      }
    },
    inStockCTA: {
      marginTop: "16px !important"
    },
    swatchImage: {
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        width: "295px !important",
        height: "175px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: "295px !important",
        height: "175px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: "230px !important",
        height: "230px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        width: "230px !important",
        height: "230px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        width: "230px !important",
        height: "230px !important"
      }
    },
    cta: {
      color: "#000000",
      fontWeight: 400,
      fontFamily: "RHSans-Roman, Helvetica, Arial",
      lineHeight: "12.1px",
      fontSize: "11px",
      letterSpacing: " 0.44px",
      borderBottom: "1px solid #000000",
      width: "fit-content"
    },
    title: {
      fontFamily: "RHSans-ExtraLight",
      fontSize: "13px",
      lineHeight: "14.3px",
      fontWeight: 200,
      color: "#393C41",
      marginBottom: "16px",
      letterSpacing: "0.26px",
      marginTop: "4px",

      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        width: "295px"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        width: "295px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        width: "230px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        width: "230px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        width: "230px !important"
      }
    },

    button: {
      minWidth: "30px !important",
      textTransform: "none",
      padding: "0px !important",
      "& .MuiButton-root": {
        padding: "0px !impotant"
      }
    },
    copyGrid: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        alignItems: "center"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        alignItems: "center"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        alignItems: "flex-start"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        alignItems: "flex-start"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        alignItems: "center"
      }
    }
  });
});

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#6e6e6e",
    color: "#fafafa",
    padding: "10px",
    textAlign: "center"
  }
}))(Tooltip);

export const SelectedFabricConfigurator: FC<any> = memoize(
  ({ selection, isV2 = false, data }) => {
    const classes = useStyles({});
    const mdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
    const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
    const [open, setOpen] = useState<boolean>(false);
    const [isOverflowed, setIsOverflow] = useState<boolean>(false);
    const textElementRef = useRef<HTMLInputElement | null>(null);

    const typographyClasses = useTypographyStyles({
      keys: [
        "rhBaseH5",
        "rhBaseCaption",
        "rhBaseBody2",
        "rhBaseBody1",
        "rhBaseBodyRoman"
      ]
    });
    const [dailogOpen, setDailogOpen] = useState(false);
    const handleTooltipClose = () => {
      setOpen(false);
    };

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    useEffect(() => {
      if (textElementRef.current) {
        setIsOverflow(
          textElementRef.current.scrollWidth >
            textElementRef.current.clientWidth
        );
      }
    }, [selection]);

    const handleClickOpen = () => {
      setDailogOpen(true);
    };
    const handleClose = () => {
      setDailogOpen(false);
    };

    return (
      <Grid
        container
        xs={12}
        alignItems="center"
        justify={mdDown && smUp && !isV2 ? "center" : "flex-start"}
        wrap="nowrap"
        data-testid="rh-selected-fabric-configurator"
      >
        {!isV2 && (
          <Grid
            item
            xs={4}
            sm={2}
            md={4}
            className={classes?.fabricTitle}
            id="fabric-group-material-grid"
            data-testid="fabric-group-material-grid"
          >
            <Typography
              className={classes?.groupMaterial}
              id="group-material-container"
              data-testid="group-material-container"
            >
              {selection?.groupMaterial}
            </Typography>
          </Grid>
        )}
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
            width: isV2 ? "calc(100% - 25px)" : "calc(100% - 62px)"
          }}
        >
          {!isV2 && (
            <Grid
              item
              style={{
                border: ".5px solid var(--ReimagineWarm-RH-Dark, #000)",
                width: "24px",
                height: "24px",
                minWidth: "24px"
              }}
            >
              <RHImage
                src={selection?.swatchImageLg?.url}
                alt={`Swatch ${selection?.title}`}
                aspectRatio={IMAGE_ASPECT_RATIO?.swatch}
                style={{
                  // marginRight: "6px",
                  // border: "0.5px solid var(--ReimagineWarm-RH-Dark, #000)",
                  width: "24px",
                  height: "24px"
                }}
              />
            </Grid>
          )}

          <Grid item xs="auto" className={classes?.GridStyle}>
            {mdDown ? (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <CustomTooltip
                    PopperProps={{
                      disablePortal: true
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    title={`${selection?.swatchGroupName} - ${selection?.title}`}
                  >
                    <Typography
                      ref={textElementRef}
                      className={classes?.selectedSwatchTitle}
                      onClick={isOverflowed ? handleTooltipOpen : undefined}
                      style={{ ...(isV2 && { maxWidth: "none" }) }}
                    >
                      {selection?.swatchGroupName && selection?.title
                        ? `${selection?.swatchGroupName} - ${selection?.title}`
                        : selection?.swatchGroupName || selection?.title}
                    </Typography>
                  </CustomTooltip>
                </div>
              </ClickAwayListener>
            ) : (
              <CustomTooltip
                title={`${selection?.swatchGroupName} - ${selection?.title}`}
                disableHoverListener={!isOverflowed}
              >
                <Typography
                  ref={textElementRef}
                  className={classes?.selectedSwatchTitle}
                  style={{ ...(isV2 && { maxWidth: "none" }) }}
                >
                  {selection?.swatchGroupName && selection?.title
                    ? `${selection?.swatchGroupName} - ${selection?.title}`
                    : selection?.swatchGroupName || selection?.title}
                </Typography>
              </CustomTooltip>
            )}
          </Grid>
          {!isV2 && (
            <Grid>
              {xsDown ? (
                <>
                  <Button className={classes.button} onClick={handleClickOpen}>
                    <RHInfoIconV2 style={{ color: "#000" }} />
                  </Button>
                  <Dialog fullScreen open={dailogOpen} onClose={handleClose}>
                    <IconButton
                      onClick={handleClose}
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end"
                      }}
                    >
                      <RHCloseIcon />
                    </IconButton>
                    <DialogContent style={{ padding: 0 }}>
                      <Grid
                        container
                        spacing={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={
                            !selection?.optionCopy || !selection?.title ? 12 : 4
                          }
                          className={classes.copyGrid}
                        >
                          <RHImage
                            imageClassName={classnames(classes.swatchImage)}
                            src={selection?.swatchImageLg?.url}
                            alt={selection?.title}
                          />
                          <Typography className={classes.title}>
                            {selection?.title}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={8}
                          style={{ maxWidth: "295px", padding: 0 }}
                        >
                          <Typography
                            className={classnames([
                              classes.tooltipSubTitle,
                              typographyClasses.rhBaseCaption
                            ])}
                            dangerouslySetInnerHTML={{
                              __html: selection?.optionCopy || selection?.title
                            }}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                </>
              ) : (
                <>
                  {" "}
                  <SwatchToolTip
                    copy={
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          md={
                            !selection?.optionCopy || !selection?.title ? 12 : 4
                          }
                          className={classes.copyGrid}
                        >
                          <RHImage
                            imageClassName={classnames(classes.swatchImage)}
                            src={selection?.swatchImageLg?.url}
                            alt={selection?.title}
                          />
                          <Typography className={classes.title}>
                            {selection?.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Typography
                            className={classnames([
                              classes.tooltipSubTitle,
                              typographyClasses.rhBaseCaption
                            ])}
                            dangerouslySetInnerHTML={{
                              __html: selection?.optionCopy || selection?.title
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
);
