import React, { FC, useCallback, useRef, useState } from "react";
import { GridList, Theme, Grid, useMediaQuery } from "utils/material-ui-core";
import classNames from "classnames";
import { PDP_LAYOUT } from "utils/constants";
import RHRPreviousArrow from "component-rhr-previous-arrow";
import RHRNextArrow from "component-rhr-next-arrow";
interface SliderProps {
  arrowOffset?: number;
  layout?: PDP_LAYOUT | null;
  totalCollectionItems?: number;
  isYmal?: boolean;
}
const Slider: FC<SliderProps> = ({
  children,
  arrowOffset = 0,
  layout,
  totalCollectionItems = 0,
  isYmal = false
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("md"));
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const [slideInView, setSlideinView] = useState(1);
  const totalSlides =
    totalCollectionItems > 2 ? Math.ceil(totalCollectionItems / 3) : 1; // 3 is the grid column

  const handlePeviewArrow = useCallback(() => {
    slideInView > 1 && setSlideinView(slideInView - 1);
    listRef.current?.scrollBy({
      top: -listRef.current?.clientHeight,
      left: -listRef.current?.clientWidth,
      behavior: "smooth"
    });
  }, []);
  const handleNextArrow = useCallback(() => {
    slideInView < totalSlides && setSlideinView(slideInView + 1);
    listRef.current?.scrollBy({
      top: listRef.current?.clientHeight,
      left: listRef.current?.clientWidth,
      behavior: "smooth"
    });
  }, []);
  return (
    <Grid
      className={isYmal ? "" : "h-full"}
      container
      alignItems="center"
      id={"component-slider"}
    >
      <Grid
        item
        className={isYmal ? "h-full flex items-center cursor-pointer" : ""}
        onClick={isYmal ? handlePeviewArrow : () => {}}
      >
        <RHRPreviousArrow
          className={classNames([
            isYmal ? `pr-20 xs:pr-11 w-[22px] sm:w-9` : "",
            `!relative visible w-4 opacity-100 max-h-12 p-0 sm:max-h-14 ${
              layout === PDP_LAYOUT.SQUARE ? "sm:px-10" : ""
            }`,
            `${slideInView === 1 ? "text-gray-500" : "text-black"} m-0`,
            `!p-0 w-4 h-12 sm:w-8 sm:h-14 md:w-10 md:h-14 xl:!w-10 xl:h-14 ${
              isYmal ? "h-full" : ""
            }`,
            layout === PDP_LAYOUT.SQUARE && smUp
              ? "-right-8 md:-right-10 xl:-right-20"
              : "",
            slideInView === 1 ? "disableButton" : ""
          ])}
          carouselArrowStyles={{
            fontSize: "2rem",
            width: 12,
            fill: "none",
            ...(isYmal ? { color: "#000000" } : {})
          }}
          arrowsColor={slideInView === 1 ? "#D4D2CF" : "rgba(0, 0, 0)"}
          onClick={handlePeviewArrow}
        />
      </Grid>
      <Grid item xs={true} className="overflow-hidden">
        <GridList
          ref={listRef}
          cols={3}
          cellHeight="auto"
          className={classNames(
            "h-full !flex !flex-nowrap overflow-x-hidden !p-0"
          )}
          spacing={(smDown && 16) || (mdDown && 33) || 44}
        >
          {children}
        </GridList>
      </Grid>
      <Grid
        item
        className={isYmal ? "h-full flex items-center cursor-pointer" : ""}
        onClick={isYmal ? handleNextArrow : () => {}}
      >
        <RHRNextArrow
          className={classNames([
            isYmal
              ? `pr-20 xs:pr-11 w-[22px] sm:w-9`
              : `!p-0 w-4 h-12 sm:w-8 sm:h-14 md:w-10 md:h-14 xl:!w-10 xl:h-14 ${
                  isYmal ? "h-full" : ""
                }`,
            `!relative visible w-4 opacity-100 max-h-12 p-0 sm:max-h-14 ${
              layout === PDP_LAYOUT.SQUARE ? "sm:px-10" : ""
            }`,
            `${
              slideInView === totalSlides ? "text-gray-500" : "text-black"
            } right-0`,
            layout === PDP_LAYOUT.SQUARE && smUp
              ? "-right-8 md:-right-10 xl:-right-20"
              : "",
            slideInView === totalSlides ? "disableButton" : ""
          ])}
          carouselArrowStyles={{
            fill: "none",
            ...(isYmal ? { color: "#000000" } : {})
          }}
          arrowsColor={
            slideInView === totalSlides ? "#D4D2CF" : "rgba(0, 0, 0)"
          }
          onClick={handleNextArrow}
        />
      </Grid>
    </Grid>
  );
};
export default Slider;
