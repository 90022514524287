import React, { FC, useState, useCallback, useMemo } from "react";
import { Typography, Grid } from "utils/material-ui-core";
import stripTags from "utils/stripTags";
import RHShippingDialog from "dialog-shipping";
import RHLink from "component-rh-link";
import memoize from "utils/memoize";
import useTypographyStyles from "hooks/useTypographyStyles";
import { useIsoCookies } from "hooks/useIsoCookies";
import useStyles from "../useStyles";
import { useEnv } from "hooks/useEnv";
import { usePageContent } from "customProviders/LocationProvider";
import { useCartMessages } from "hooks/useCartMessages";
import { currencyCodeToCurrencySymbolMapper } from "resources/countries-config.json";
import yn from "yn";
import { formatCurrency } from "utils/currencyHelpers";
import buildReactNodesFromArray, {
  EnhancedTextType
} from "utils/buildReactNodesFromArray";

interface ShippingDialogInterface {
  isOpen: boolean;
  menuIdx: number;
}
export interface DeliveryProps {
  delivery?: ProductSku["delivery"];
  onPostalCode?: (event: React.MouseEvent) => void;
  isSwatchPanelOpen?: boolean;
  inventory?: ProductSku["inventory"];
}

const shippingDialogDefaultValues: ShippingDialogInterface = {
  isOpen: false,
  menuIdx: 0
};

export const Delivery: FC<DeliveryProps> = ({
  delivery,
  onPostalCode,
  isSwatchPanelOpen,
  inventory
}) => {
  if (!delivery) {
    return null;
  }
  const { pageContent } = usePageContent();
  const env = useEnv();
  const FEATURE_PDP_CART_DELIVERY = yn(env.FEATURE_PDP_CART_DELIVERY);
  const classes = useStyles();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption"]
  });

  const textClasses = typographyStyles.rhBaseCaption;
  const [shippingDialogOpen, setShippingDialogOpen] = useState(
    shippingDialogDefaultValues
  );

  const handleUnlimitedShippingDialogOpen = useCallback(() => {
    setShippingDialogOpen({ isOpen: true, menuIdx: 0 });
  }, [shippingDialogOpen]);

  const handleStandardShippingDialogOpen = useCallback(() => {
    setShippingDialogOpen({ isOpen: true, menuIdx: 1 });
  }, [shippingDialogOpen]);

  const handleShippingDialogClose = useCallback(() => {
    setShippingDialogOpen(shippingDialogDefaultValues);
  }, [shippingDialogOpen]);

  const postalCode = useMemo(
    () => delivery?.postalCode || inventory?.postalCode || "",
    [delivery?.postalCode, inventory?.postalCode]
  );
  const pc = useIsoCookies(["pc"], true)?.pc || postalCode;

  const { getDeliveryInfo } = useCartMessages({
    removeShippingChargeOverride: true
  });

  //const removeShippingAmount = yn(env.FEATURE_CART_REMOVE_SHIPPING_AMOUNT);
  const removeShippingAmount = false;

  const handleDeliveryInfo = useCallback(
    (deliveryInfo: EnhancedTextType[], currencyCode, freight: number) => {
      if (deliveryInfo) {
        let updatedDeliveryInfo = [...deliveryInfo];
        const [_, deliveryLabel, deliveryPricing] = deliveryInfo;
        if (
          deliveryLabel?.displayText
            ?.toLowerCase()
            .includes("via unlimited furniture delivery") &&
          !deliveryPricing?.displayText?.includes(
            currencyCodeToCurrencySymbolMapper[currencyCode]
          ) &&
          !removeShippingAmount
        ) {
          const format =
            deliveryPricing?.displayText &&
            formatCurrency(
              (+deliveryPricing?.displayText || 0) / 100,
              currencyCode,
              false
            );
          updatedDeliveryInfo = [
            ...deliveryInfo?.slice(0, 2),
            {
              ...deliveryPricing,
              displayText: `${format}`
            },
            ...deliveryInfo?.slice(3)
          ];
        }
        const deliveryTextArr: string[] = deliveryInfo?.map(item =>
          item?.displayText?.toLowerCase().trim()
        );
        const text = deliveryInfo.filter(item => {
          return (
            item?.displayText?.toLowerCase().includes("free") ||
            item?.displayText?.toLowerCase().includes("unlimited")
          );
        });

        const deliveryText =
          text.length === 0 &&
          formatCurrency((freight || 0) / 100, currencyCode, true);
        return [
          buildReactNodesFromArray(updatedDeliveryInfo),
          freight &&
          deliveryTextArr.includes("via standard delivery shipping") &&
          !deliveryTextArr.includes("free") &&
          !removeShippingAmount ? (
            <span>
              {" "}
              for <b> {deliveryText} </b>
            </span>
          ) : (
            ""
          )
        ];
      }
    },
    []
  );

  const deliveryLine = handleDeliveryInfo(
    getDeliveryInfo({
      postalCode: pc || "",
      shipViaCode: delivery?.shipVia || "0",
      groundFreightExempt: delivery?.freightExempt,
      freight: { unlimited: delivery?.unlimitedFurnitureDelivery },
      currencyCode: delivery?.currency || "USD"
    }),
    delivery?.currency,
    Number(delivery?.shippingSurchargeAmount || 0)
  );

  return (
    <Grid item xs={12}>
      {!!deliveryLine && FEATURE_PDP_CART_DELIVERY ? (
        delivery?.unlimitedFurnitureDelivery || delivery?.freightExempt ? (
          <RHLink
            underline="always"
            onClick={e => {
              e.preventDefault();
              delivery?.freightExempt
                ? handleStandardShippingDialogOpen()
                : handleUnlimitedShippingDialogOpen();
            }}
          >
            <Typography className={typographyStyles.rhBaseCaption}>
              {deliveryLine}
            </Typography>
          </RHLink>
        ) : (
          <Typography className={typographyStyles.rhBaseCaption}>
            {deliveryLine}
          </Typography>
        )
      ) : (
        <>
          {delivery?.deliveryEstimateStatus !== "zeroEstimate" &&
          !delivery?.freightExempt ? (
            <>
              {delivery?.deliveryEstimate ? (
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={handleUnlimitedShippingDialogOpen}
                >
                  <Typography>
                    {delivery.postalCode
                      ? `${pageContent?.shippingMethod?.message1?.body1} ${delivery?.deliveryEstimate} ${pageContent?.shippingMethod?.message1?.bode2} ${pc}`
                      : `${pageContent?.shippingMethod?.message2} ${delivery?.deliveryEstimate}`}
                  </Typography>
                </span>
              ) : null}
            </>
          ) : delivery?.freightExempt &&
            delivery?.freightExemptMessage !== "" ? (
            <Typography
              className={textClasses}
              dangerouslySetInnerHTML={{
                __html: stripTags(delivery?.freightExemptMessage)
              }}
            />
          ) : (
            <RHLink
              underline="always"
              onClick={e => {
                e.preventDefault();
                handleStandardShippingDialogOpen();
              }}
            >
              <Typography className={typographyStyles.rhBaseCaption}>
                {pageContent?.STANDARD_DELIVERY_SHIPPING}
              </Typography>
            </RHLink>
          )}
        </>
      )}
      <Typography className={textClasses}>
        {delivery.deliveryStateMessage}
      </Typography>
      <RHShippingDialog
        open={shippingDialogOpen.isOpen}
        menuIdx={shippingDialogOpen.menuIdx}
        onCloseModal={handleShippingDialogClose}
      />
    </Grid>
  );
};

Delivery.defaultProps = {};

export default memoize(Delivery);
