import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_XS } from "utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: {
      textAlign: "center",
      justifyContent: "center",
      width: "466px"
    },
    paper: {
      width: BREAKPOINT_SM,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "100%",
        margin: 0,
        position: "absolute",
        top: 0,
        padding: "20px"
      }
    },
    priceCaptionText: {
      color: "#000",
      fontFamily: "RHSans-Light",
      fontSize: "11px",
      fontWeight: 300,
      lineHeight: "120%",
      letterSpacing: " 0.44px",
      textAlign: "start"
    },
    textAlignMent: {
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        textAlign: "left"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        textAlign: "center"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        textAlign: "left"
      }
    }
  })
);

export default useStyles;
