/**
 * Reference: https://reactrouter.com/web/guides/scroll-restoration
 */

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getGlobal } from "utils-globals/getGlobal";
import memoize from "utils/memoize";
import yn from "yn";

export default memoize(
  function ScrollManager() {
    const { pathname } = useLocation();

    useEffect(() => {
      const { FEATURE_SCROLL_MANAGER } = getGlobal("_env");

      if (yn(FEATURE_SCROLL_MANAGER)) {
        window.scrollTo(0, 0);
      }
    }, [pathname]);

    return null;
  },
  { deep: false }
);
