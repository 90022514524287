import React, { FC } from "react";
import {
  Grid,
  useTheme,
  Card,
  CardMedia,
  CardContent,
  Typography,
  GridList,
  GridListTile,
  makeStyles,
  createStyles,
  Theme,
  useMediaQuery
} from "utils/material-ui-core";
import RHImage from "component-rh-image";
import he from "he";
import { FONT_THIN, FONT_REGULAR, IMAGE_ASPECT_RATIO } from "utils/constants";
import RHLink from "component-rh-link";
import OptionsDetailsList from "component-options-details-list";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import {
  PriceRangeDisplay,
  UniversalPriceRangeDisplay
} from "component-price-range-display";
import { convertSkuResponsePriceInfoToRange } from "component-price-range-display/utils/convertSkuResponsePriceInfoToRange";
import { PAGE_BG_GREY } from "utils/constants";
import { useEnv } from "hooks/useEnv";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { usePageContent } from "customProviders/LocationProvider";
import { useRhUserAtomValue } from "hooks/atoms";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    additionalSwatches: {
      letterSpacing: 0.18,
      color: "#000000",
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(11),
      fontWeight: FONT_THIN,
      verticalAlign: "top",
      textAlign: "right",
      [theme.breakpoints.up("md")]: {
        fontWeight: FONT_REGULAR,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(16),
        letterSpacing: 0.2,
        color: "#666666"
      }
    },
    productCartTitle: {
      textTransform: "uppercase"
    },
    productCartSubTitle: {
      fontSize: theme.typography.pxToRem(13),
      textTransform: "capitalize",
      color: "#999999",
      letterSpacing: "0.2px"
    },
    productCartSubTitleValue: {
      fontSize: theme.typography.pxToRem(12),
      textTransform: "uppercase",
      color: "#666666",
      letterSpacing: "0.2px"
      // paddingBottom: "8px"
    },
    gridAlignCenter: {
      justifyContent: "center"
    }
  })
);

// TODO: this component should be unnecessary as it is nearly identical to the ProductCard
// This component only includes the swatches GridList
// However, the types of SearchRecordProduct and Product do not have the same data points
// making this component necessary for now.
export interface SearchResultCardProps {
  product: SearchRecordProduct;
  isStocked?: number;
  productSku?: SearchRecordSku;
  disableSwatches?: boolean;
  index?: number;
}

export interface SkuLabelProps {
  label: string;
  value: string;
  centerAlignFlag?: boolean;
  styles?: CSSProperties;
}

export const SkuLabelMobile: FC<SkuLabelProps> = ({
  label,
  value,
  centerAlignFlag,
  styles
}) => {
  const classes = useStyles();
  const typographyClasses = useTypographyStyles({
    keys: ["rhBaseH7", "rhBaseBody2", "rhBaseBody3"]
  });

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: centerAlignFlag ? "center" : "left",
          ...styles
        }}
      >
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            justifyContent: centerAlignFlag ? "center" : "left",
            textAlign: centerAlignFlag ? "end" : "left",
            flexGrow: "unset",
            width: "unset",
            flexBasis: "unset"
          }}
        >
          <Typography
            component="h5"
            variant="h5"
            className={classNames([
              classes.productCartSubTitle,
              typographyClasses.rhBaseBody3
            ])}
            style={{
              textTransform: "uppercase",
              color: "rgba(137, 136, 134, 1)",
              marginRight: 5,
              width: "auto",
              wordBreak: "keep-all"
            }}
          >
            {`${label}:`}
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          xs={8}
          style={{
            justifyContent: centerAlignFlag ? "center" : "left",
            flexGrow: "unset",
            width: "unset",
            flexBasis: "unset"
          }}
        >
          <Typography
            component="h5"
            variant="h5"
            className={classNames([
              classes.productCartSubTitleValue,
              typographyClasses.rhBaseBody3
            ])}
            style={{
              color: "rgb(0, 0, 0)",
              // fontSize: 12,
              textTransform: styles?.textTransform ?? "uppercase",
              width: "auto"
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: `${he.decode(value)}`
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const SearchResultCard: FC<SearchResultCardProps> = ({
  product,
  isStocked,
  productSku,
  disableSwatches,
  index
}) => {
  const { pageContent } = usePageContent();
  const theme = useTheme();
  const classes = useStyles();
  const typographyClasses = useTypographyStyles({
    keys: ["rhBaseH7", "rhBaseCaption", "rhBaseBody2"]
  });
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const swatchesToDisplay = product?.swatchInfo?.swatchesToDisplay || [];
  const { userType } = useRhUserAtomValue();
  const env = useEnv();
  const { setStorageValueWrapper } = useCookiesWithPermission();

  return (
    <RHLink
      underline="none"
      to={{
        pathname: "/catalog/product/product.jsp",
        search:
          product?.repositoryId !== productSku?.fullSkuId && isStocked === 1
            ? `?productId=${product.repositoryId}&fullSkuId=${productSku?.fullSkuId}`
            : `?productId=${product.repositoryId}`,
        state: { categoryId: "search" }
      }}
      id={`${productSku?.fullSkuId}__${index}`}
      onClick={() =>
        setStorageValueWrapper({
          storageKey: "pdpViewItem",
          value: `${productSku?.fullSkuId}__${index}`
        })
      }
    >
      <Card
        elevation={0}
        square
        style={{
          backgroundColor: env?.FEATURE_BG_GREY ? PAGE_BG_GREY : "white"
        }}
      >
        <CardMedia style={{ paddingBottom: theme.spacing(1) }}>
          <RHImage
            src={product?.imageUrl || ""}
            alt={product?.displayName || ""}
            objectFit={"contain"}
            preset="pg-card-xs"
            aspectRatio={IMAGE_ASPECT_RATIO["productCard"]}
          />
        </CardMedia>
        {!disableSwatches && isStocked !== 1 && (
          <GridList
            cols={5}
            cellHeight="auto"
            style={{
              marginBottom: 15
            }}
          >
            {swatchesToDisplay.slice(0, 4).map((swatch, index) => (
              <GridListTile key={`swatch-${swatch.swatchId || index}`}>
                <RHImage
                  src={swatch.imageUrl}
                  alt={swatch.imageUrl}
                  style={{ height: mdUp ? 16 : 9 }}
                />
              </GridListTile>
            ))}
            <GridListTile style={{ height: mdUp ? 20 : 14 }}>
              <Typography
                noWrap
                className={classNames([
                  typographyClasses.rhBaseCaption,
                  classes.additionalSwatches
                ])}
              >
                {!!product?.swatchInfo?.numAdditionalSwatchesAvailable
                  ? `+${product.swatchInfo.numAdditionalSwatchesAvailable}`
                  : ""}
              </Typography>
            </GridListTile>
          </GridList>
        )}
        <CardContent style={{ padding: 0 }}>
          <Typography
            component="h5"
            className={classNames([
              classes.productCartTitle,
              typographyClasses.rhBaseBody2
            ])}
            style={{
              paddingBottom: 15
            }}
          >
            {product.newProduct && (
              <span style={{ fontWeight: 600, letterSpacing: 1 }}>
                {" "}
                {pageContent?.INTRODUCING}{" "}
              </span>
            )}
            <span
              dangerouslySetInnerHTML={{
                __html: he.decode(product?.displayName || "")
              }}
            ></span>
          </Typography>
          {isStocked === 1 && (
            <div style={{ paddingBottom: "10px" }}>
              {product.skuPriceInfo && (
                <>
                  {mdUp ? (
                    <OptionsDetailsList
                      itemId={productSku?.fullSkuId || ""}
                      options={product.skuPriceInfo?.skuOptions || []}
                      isStocked={true}
                    />
                  ) : (
                    <>
                      {product.skuPriceInfo?.skuOptions?.map(
                        (option: ProductItemOption) => (
                          <SkuLabelMobile
                            label={option.optionType}
                            value={option.label}
                          />
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {!product.skuPriceInfo &&
            !product.priceInfo?.isCustomProduct &&
            isStocked === 0 &&
            product?.priceInfo && (
              <PriceRangeDisplay data={product?.priceInfo} />
            )}
          {isStocked === 1 && !product.priceInfo && (
            <>
              {product.skuPriceInfo ? (
                <UniversalPriceRangeDisplay
                  topLabel={pageContent?.STARTING_AT}
                  range={convertSkuResponsePriceInfoToRange(
                    null,
                    product?.skuPriceInfo,
                    userType
                  )}
                  currencyLabel={product?.skuPriceInfo?.currencyLabel || ""}
                  currencySymbol={product?.skuPriceInfo?.currencySymbol || ""}
                  priceMessage={""}
                  priceMessagePrice={undefined}
                />
              ) : (
                <>{pageContent?.NO_PRICE_AVAILABLE}</>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </RHLink>
  );
};

SearchResultCard.defaultProps = {};

export default SearchResultCard;
