import React, { FC, useMemo } from "react";
import { Typography, Grid } from "utils/material-ui-core";
import useTypographyStyles from "hooks/useTypographyStyles";
import RHUnderline from "component-rh-underline";
import { useIsoCookies } from "hooks/useIsoCookies";
import memoize from "utils/memoize";
import { useEnv } from "hooks/useEnv";
import useStyles from "../useStyles";
import { usePageContent } from "customProviders/LocationProvider";
import { CONFIGURE_ZIPCODE_DEFAULT_MSG } from "resources/page-level-resources-schemas/product.json";
import { useParams2 } from "hooks/useParams";

export interface InventoryProps {
  inventory?: ProductSku["inventory"];
  delivery?: ProductSku["delivery"];
  onPostalCode?: () => void;
  showSPOmsg?: boolean;
  isDisabled?: boolean;
  skuInventoryMessage?: string;
  spo?: boolean;
  inStock?: boolean;
  isSwatchPanelOpen?: boolean;
  isNewPDPLayout?: boolean;
  loading?: boolean;
}

export const Inventory: FC<InventoryProps> = ({
  inventory,
  delivery,
  onPostalCode,
  showSPOmsg,
  isDisabled,
  skuInventoryMessage,
  isSwatchPanelOpen,
  isNewPDPLayout,
  loading
}) => {
  const { pageContent } = usePageContent();
  const typographyClasses = useTypographyStyles({
    keys: ["mediumFontWeight", "rhBaseBody1", "rhBaseCaption"]
  });

  const { params } = useParams2<{ [key: string]: string }>(
    { version: "" },
    { toLowerCase: true }
  );

  const env = useEnv();

  const classes = useStyles();

  const postalCode = useMemo(
    () => delivery?.postalCode || inventory?.postalCode || "",
    [delivery?.postalCode, inventory?.postalCode]
  );
  const postalCodeData = useIsoCookies(["pc"], true)?.pc || postalCode;

  const showMessage =
    ((showSPOmsg && inventory?.preOrder) || showSPOmsg) &&
    !inventory?.needPostalCode;

  const lineItemMessage = `${
    skuInventoryMessage?.length
      ? skuInventoryMessage
      : inventory?.lineItemMessage
  }`;

  return (
    <>
      {(!loading || lineItemMessage?.length) && (
        <Grid
          item={!isNewPDPLayout}
          xs={12}
          id="inventory-message-wrapper"
          data-testid="inventory-message-wrapper"
        >
          <Typography
            id="inventory_delivery_message"
            data-testid="inventory_delivery_message"
            className={
              isNewPDPLayout
                ? isSwatchPanelOpen
                  ? classes.priceCaptionText
                  : ""
                : typographyClasses.rhBaseBody1
            }
          >
            {/* do not remove this in merge conflicts, it is for Updated PDP */}
            <span id="inventory-message" data-testid="inventory-message">
              {isNewPDPLayout ? (
                <>{skuInventoryMessage}</>
              ) : (
                <>
                  {(!!postalCode &&
                    showMessage &&
                    postalCode !== "null" &&
                    !isDisabled &&
                    skuInventoryMessage) ||
                  (inventory?.lineItemMessage && !isDisabled)
                    ? `${
                        skuInventoryMessage?.length
                          ? skuInventoryMessage
                          : inventory?.lineItemMessage
                      }`
                    : pageContent?.CONFIGURE_ZIPCODE_DEFAULT_MSG ??
                      CONFIGURE_ZIPCODE_DEFAULT_MSG}{" "}
                </>
              )}
            </span>{" "}
            {(isNewPDPLayout && skuInventoryMessage) || !isNewPDPLayout ? (
              <>
                <span>
                  {postalCode === ""
                    ? pageContent?.FOR
                    : pageContent?.TO || "to"}
                </span>{" "}
                <RHUnderline
                  onClick={onPostalCode}
                  data-testid="postal-code-dialog-opener"
                >
                  {postalCodeData && postalCodeData !== "null"
                    ? `${postalCodeData}`
                    : `${pageContent?.YOUR_LOCATION}`}
                  {!isNewPDPLayout && "."}
                </RHUnderline>
              </>
            ) : null}
          </Typography>
        </Grid>
      )}
    </>
  );
};

Inventory.defaultProps = {};

export default memoize(Inventory);
