import RHLink from "component-rh-link";
import React, { FC } from "react";
import { Grid, Typography, useTheme } from "utils/material-ui-core";
import { ModalTrigger } from "component-modal-trigger";
import { useEnv } from "hooks/useEnv";
import memoize from "utils/memoize";
import useTypographyStyles from "hooks/useTypographyStyles";
import { usePageContent } from "customProviders/LocationProvider";
import useStyles from "../useStyles";
import { getReturnPolicyLink } from "utils/getReturnPolicyLink";
import getCountryFromUrl, { getLanguageFromUrl } from "utils/getCountryFromUrl";
import { useAppId } from "hooks/useAppId";
import { useRhUserAtomValue } from "hooks/atoms";

export interface RestrictionsProps {
  restrictions?: Pick<
    ProductRestrictions,
    "returnPolicyMessage" | "countryRestrictions" | "additionalMessages"
  >;
  availabilityStatus?: string; // cart.lineItem.availabilityStatus
  hideReturnPolicyMessage: boolean;
  hideCountryRestrictions: boolean;
  hideReturnPolicyLink?: boolean;
  loading?: boolean;
  isSwatchPanelOpen?: boolean;
  spoOverrideMessage?: string;
  lineBreak?: boolean;
}
export const Restrictions: FC<RestrictionsProps> = ({
  restrictions,
  availabilityStatus,
  hideReturnPolicyMessage,
  hideReturnPolicyLink,
  hideCountryRestrictions,
  isSwatchPanelOpen,
  spoOverrideMessage,
  lineBreak
}) => {
  const { pageContent } = usePageContent();
  const theme = useTheme();
  const env = useEnv();
  const { isEstore } = useAppId();
  const classes = useStyles();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption"]
  });
  const country = getCountryFromUrl();
  const { raw: language } = getLanguageFromUrl();

  const textStyle = typographyStyles.rhBaseCaption;
  const returnPolicyRHLinkProps = isEstore
    ? {
        to: getReturnPolicyLink(country)
      }
    : {
        href: getReturnPolicyLink(country, {
          origin: "https://rh.com",
          countryLanguagePrefix: `${country.toLowerCase()}/${language}`
        }),
        target: "_blank"
      };

  const { userType } = useRhUserAtomValue();

  return restrictions ? (
    <Grid item id="component-sku__restrictions">
      {!hideReturnPolicyMessage &&
        userType !== "CONTRACT" &&
        !spoOverrideMessage && (
          <>
            <Typography
              className={typographyStyles.rhBaseCaption}
              display="inline"
            >
              {availabilityStatus === "dropship"
                ? pageContent?.THIS_ITEM_IS_SPECIAL_ORDER_AND_CANNOT_BE_RETURNED
                : restrictions?.returnPolicyMessage ||
                  pageContent?.THIS_ITEM_IS_SPECIAL_ORDER_AND_CANNOT_BE_RETURNED}
            </Typography>
          </>
        )}{" "}
      {!hideReturnPolicyMessage &&
        userType !== "CONTRACT" &&
        spoOverrideMessage && (
          <>
            <Typography
              className={typographyStyles.rhBaseCaption}
              display="inline"
            >
              {spoOverrideMessage}
            </Typography>
            &nbsp;
          </>
        )}
      {userType === "CONTRACT" || hideReturnPolicyLink ? null : (
        <Typography className={textStyle} display="inline">
          {lineBreak && <br />}
          {pageContent?.LEARN_MORE_ABOUT_OUR}&nbsp;
          <RHLink
            {...returnPolicyRHLinkProps}
            underline="always"
            display="inline"
          >
            <Typography className={textStyle} display="inline" component="span">
              {pageContent?.RETURN_POLICY}
            </Typography>
          </RHLink>
        </Typography>
      )}
      <Typography className={textStyle}>
        {!hideCountryRestrictions &&
          restrictions.countryRestrictions &&
          restrictions.countryRestrictions.map((item, index) => (
            <Typography
              key={`country-restrictions-${index}`}
              className={textStyle}
              component="span"
              style={{ marginTop: theme.spacing(1) }}
            >
              {item}
            </Typography>
          ))}
        {restrictions.additionalMessages && (
          <ModalTrigger>
            {Object.keys(restrictions.additionalMessages)
              .filter(
                key =>
                  key !== "__typename" && !!restrictions.additionalMessages[key]
              )
              .map((item, index) => (
                <>
                  <br />
                  <Typography
                    key={`additional-messages-${index}`}
                    data-testid={`delivery-message-${index}`}
                    className={typographyStyles.rhBaseCaption}
                    style={{ display: "inline" }}
                    dangerouslySetInnerHTML={{
                      __html: restrictions.additionalMessages[item]
                    }}
                  />
                </>
              ))}
          </ModalTrigger>
        )}
      </Typography>
    </Grid>
  ) : null;
};
Restrictions.defaultProps = {
  hideReturnPolicyMessage: false,
  hideCountryRestrictions: false,
  loading: false
};
export default memoize(Restrictions);
